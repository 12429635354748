<template>
    <div>
  
      <el-table
        :id="`sortTable${id}`"
        row-key="id"
        :data="modelList"
        class="thead-light"
        stripe
        :style="{ marginTop: '10px' }"
      >
        <el-table-column
          v-for="(item, index) in sortColumn"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :min-width="item.min_width"
          :sortable="item.sortable"
        >
          <template slot-scope="scope">
            <!--排序 -->
            <div v-if="item.prop === 'sort'">
              <i class="el-icon-sort"></i>
            </div>
            <!-- 简介-->
            
            <div v-else-if="item.prop === 'description'">
              <el-popover
                placement="top-start"
                title="简介"
                width="400"
                trigger="hover"
                :content="scope.row[item.prop]"
              >
                <el-button slot="reference" type="text">简介</el-button>
              </el-popover>
            </div>
            <!-- 头像 -->
            <list-image
              v-else-if="item.prop === 'image'"
              :src="scope.row[item.prop]"
              borderRadius="50%"
              fit="cover"
  
            ></list-image>
            <span v-else>{{ scope.row[item.prop] | placeholder }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button
              style="color: #3576ff"
              size="small"
              type="text"
              @click="handleEditMember(scope.row)"
              >编辑
            </el-button>
            <el-button
              style="color: #3576ff"
              size="small"
              type="text"
              @click="handleDelMember(scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex-align-center">
        <div v-show="limit>modelList.length" class="add-btn" @click="handleAddMember">+添加会员</div>
        <p  v-if="tips" class="tips">
         {{ tips }}</p>
      </div>
      <AddMemberDialog
        v-model="openAddMember"
        @addMemberSuccess="addMemberSuccess"
        @editMemberSuccess="editMemberSuccess"
        :currentMember="currentMember"
        :disableMembers="modelList"
        :defaultField="defaultField"
      ></AddMemberDialog>
    </div>
  </template>
  
  <script>
  import AddMemberDialog from "./AddMemberDialog";
  import ListImage from "@/base/components/List/ListImage";
  import Sortable from "sortablejs/modular/sortable.core.esm.js";
  
  export default {
    components: { AddMemberDialog, ListImage },
    model: {
      prop: "sortList",
      event: "changeSortList",
    },
    props: {
      sortList: {
        type: Array,
        default: () => [],
      },
      tips:{
        type:String,
        default:''
      },
      limit:{
        type:Number,
        default:999
      },
      id:{
        type:[String,Number],
        default:1
      },
      defaultField:{
        type:Array,
        default:()=>[]
      }
    },
    data() {
      return {
        sortColumnList: [
          { label: "拖拽排序", prop: "sort", min_width: 100 },
          { label: "头像", prop: "image", min_width: 100 },
          { label: "会员名称", prop: "name", min_width: 100 },
          { label: "会内职务", prop: "position", min_width: 100 },
          { label: "单位名称", prop: "unit_name", min_width: 100 },
          { label: "单位职务", prop: "unit_position", min_width: 100 },
          { label: "会员行业声誉", prop: "reputation", min_width: 140 },
        ],
        // 会员弹窗控制
        openAddMember: false,
        //   当前编辑的会员
        currentMember: {
          id: "",
          name: "",
          position: "",
          description: "",
          image: "",
          unit_name: "",
          unit_position: "",
        },
      };
    },
    computed: {
      modelList: {
        get() {
          return this.$props.sortList;
        },
        set(val) {
          this.$emit("changeSortList", val);
        },
      },
      sortColumn(){
        return this.sortColumnList.filter(t=>this.$props.defaultField.findIndex(f=>f.label==t.prop)=== -1)
      }
    },
    methods: {
      //初始化拖拽表格
      initSortable() {
        this.$nextTick(() => {
          const sortTable = document.querySelector(
            `#sortTable${this.$props.id}  .el-table__body>tbody`
          );
          console.log(sortTable);
          this.$sortTable = Sortable.create(sortTable, {
            animation: 150,
            ghostClass: "sortable-ghost",
            onEnd: (event) => {
              console.log(event.oldIndex, event.newIndex);
              if (event.oldIndex === event.newIndex) {
                return;
              }
              // 拿到拖拽的item
              const oldItem = this.modelList[event.oldIndex];
              // 删除原本位置
              this.modelList.splice(event.oldIndex, 1);
              // 添加到新的位置
              this.modelList.splice(event.newIndex, 0, oldItem);
              let idArray = this.modelList.map((item) => item.id);
            },
          });
          //阻止火狐拖拽新建新页面
          document.body.addEventListener("drop", this.preventDrop, false);
        });
      },
      //销毁拖拽表格
      destroySortable() {
        if (this.$sortTable) this.$sortTable.destroy();
        document.body.removeEventListener("drop", this.preventDrop, false);
      },
      //阻止默认drop事件
      preventDrop(event) {
        event.preventDefault();
        event.stopPropagation();
      },
  
      handleEditMember(row) {
        this.currentMember = row;
        this.openAddMember = true;
      },
      handleAddMember() {
        if(this.modelList.length>=this.limit){
          this.$message.error(`最多只能选择${this.limit}个会员`);
          return
        }
        this.currentMember = {
          id: "",
          name: "",
          position: "",
          description: "",
          image: "",
          unit_name: "",
          unit_position: "",
        };
        this.openAddMember = true;
      },
      // 删除此会员
      handleDelMember(row) {
        this.$confirm("是否删除此会员?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let index = this.modelList.findIndex((item) => item.id === row.id);
            this.modelList.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {});
      },
      // 添加会员成功
      addMemberSuccess(form) {
        this.modelList.push(form);
      },
      // 编辑会员成功
      editMemberSuccess(form) {
        console.log(form);
        let index = this.modelList.findIndex((item) => item.id === form.id);
        this.$set(this.modelList, index, form);
        console.log(this.modelList);
      },
    },
    mounted() {
      this.initSortable();
    },
    beforeDestroy() {
      //销毁拖拽表格
      this.destroySortable();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .tips {
    margin-left: 20px;
    font-size: 12px;
    color: #c0c4cc;
    line-height: 24px;
  }
  .add-btn {
    cursor:pointer;
  margin-top: 10px;
  width: 100%;
  width: 100%;
  height: 40px;
  border: 1px solid transparent;
  text-align: center;
  line-height: 40px;
  color: #ccc;
  background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(-45deg, #ccc 0, #ccc 0.25em, white 0, white 0.5em);
}
  </style>
  