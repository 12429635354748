<template>
  <div class="content-box">
    <el-dialog
      :title="type === 'add' ? '添加会员' : '编辑会员'"
      :visible="is_show"
      width="500px"
      class="dialog-vertical"
      @close="close()"
      @open="openDialogFn"
    >
      <el-form
        size="medium"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="90px"
        ref="form"
        @submit.native.prevent="() => {}"
      >
        <el-form-item prop="id" label="选择会员：">
          <el-button
            :disabled="type === 'edit'"
            type="primary"
            @click="handleSelectMember"
            >{{ form.name ? form.name : '选择会员' }}</el-button
          >
        </el-form-item>
        <el-form-item prop="unit_name" label="单位名称：">
          <!-- <el-input v-model="form.unit_name" placeholder="请输入"></el-input> -->
          <el-autocomplete
            style="width: 100%"
            popper-class="my-autocomplete"
            v-model="form.unit_name"
            :fetch-suggestions="querySearch"
            placeholder="请输入"
            @select="autocompleteSelect"
          >
            <template slot-scope="{ item }">
              <div class="company">{{ item.company_name }}</div>
              <span class="position">{{ item.position_name }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item prop="unit_position" label="单位职务：">
          <el-input
            v-model="form.unit_position"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item prop="reputation" label="会员声誉：">
          <el-input
            v-model="form.reputation"
            placeholder="如“广东省政协委员”，“优秀民营企业家”等"
            maxlength="15"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <SelectMember
      v-model="openSelect"
      :selectType="0"
      :disableMembers="disableMembers"
      @changeSelect="handleSelect"
      :hash="true"
    />
  </div>
</template>
<script>
import SelectMember from '@/modules/common/components/MemberSelector'
import { getCompanyPositions } from '@/modules/organization-message/api/message-config'
export default {
  name: 'EntranceDialog',
  model: {
    prop: 'open',
    event: 'changeOpen',
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
    currentMember: {
      type: Object,
      default: () => {},
    },
    disableMembers: {
      type: Array,
      default: () => [],
    },
  },
  components: { SelectMember },
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open
      },
      set(val) {
        this.$emit('changeOpen', val)
      },
    },
  },
  watch: {
    'form.id'(newVal) {
      this.form.user_id = newVal
      if (newVal) {
        getCompanyPositions({ user_id: newVal }).then((res) => {
          this.companyPositionsList = res.data
        })
      } else {
        this.companyPositionsLis = []
      }
    },
  },

  data() {
    return {
      form: {},
      rules: {
        id: [{ required: true, message: '请选择会员', trigger: 'blur' }],
        unit_name: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
        ],
        unit_position: [
          { required: true, message: '请输入单位职务', trigger: 'blur' },
        ],
        reputation: [
          { required: true, message: '请输入声誉', trigger: 'blur' },
        ],
      },
      loading: false,
      openSelect: false,
      //   添加还是编辑
      type: 'add',
      companyPositionsList: [],
    }
  },
  methods: {
    openDialogFn() {
      this.form = JSON.parse(JSON.stringify(this.$props.currentMember))
      if (this.form.id) {
        this.type = 'edit'
      } else {
        this.type = 'add'
      }
    },
    autocompleteSelect(val) {
      console.log(val, 'val')
      this.form.unit_name = val.company_name
      this.form.unit_position = val.position_name
    },
    querySearch(queryString, cb) {
      let restaurants = this.companyPositionsList
      // company_name, position_name
      let results = queryString
        ? restaurants.filter((t) => t.company_name.indexOf(queryString) !== -1)
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    // 选择会员回调
    handleSelect(selectArr) {
      this.form.id = selectArr[0].user_id
      this.form.name = selectArr[0].nickname
      this.form.position = selectArr[0].position_name
      this.form.image = selectArr[0].avatar
    },
    handleSelectMember() {
      this.openSelect = true
    },
    close() {
      this.form = {
        id: '',
        name: '',
        position: '',
        description: '',
        image: '',
        unit_name: '',
        unit_position: '',
        send_word:'',
      }
      this.is_show = false
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type === 'add') {
            this.$emit('addMemberSuccess', this.form)
          } else {
            this.$emit('editMemberSuccess', this.form)
          }
          this.close()
        } else {
          console.log('error submit!!')
          // this.close();

          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.my-autocomplete {
  li {
    line-height: normal !important;
    padding: 7px !important;

    .company {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .position {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .position {
      color: #ddd;
    }
  }
}
</style>
