<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="100px"
      :model="form"
      ref="saveForm"
    >
      <el-form-item label="核心成员：">
        <MemberTable :id="1" v-model="form.core"></MemberTable>
      </el-form-item>
      <el-form-item label="精华内容：">
        <el-radio-group v-model="form.display">
          <el-radio
            v-for="item in essenceList"
            :key="item.value"
            :label="item.value"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>

        <ActivitiesTable v-show="form.display  === 'activities'" v-model="form.activities" :limit="4"/>
        <ActiveTable v-show="form.display  === 'active'" v-model="form.active"  :limit="4"/>
        <CycCirclesTable :id="2" v-show="form.display  === 'cyc_circles'" v-model="form.cyc_circles" :limit="4"/>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button @click="cancel">取消</el-button>
      <el-button @click="$emit('openCode')">预览</el-button>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import MemberTable from '@/modules/organization-message/components/MemberTable'
import CycIntroducePage from '../../mixins/CycIntroducePage'
import ActiveTable from '@/modules/invite-members/components/CycIntroducePage/ActiveTable/DraggableList.vue'
import CycCirclesTable from '@/modules/invite-members/components/CycIntroducePage/CycCirclesTable/Table.vue'
import ActivitiesTable from '@/modules/invite-members/components/CycIntroducePage/ActivitiesTable/DraggableList.vue'
export default {
  mixins: [CycIntroducePage],
  components: { FixedActionBar, MemberTable,ActiveTable,ActivitiesTable,CycCirclesTable },
  model: {
    prop: 'previewForm',
    event: 'setPreviewForm',
  },
  props: {
    previewForm: Object,
    formData: Object,
  },
  data() {
    return {
      key: 'members',
      loading: true,
      essenceList: [
        {
          name: '人脉',
          value: 'active',
        },
        {
          name: '活动',
          value: 'activities',
        },
        {
          name: '资源',
          value: 'cyc_circles',
        },
      ],
    }
  },
  computed: {
    form: {
      get() {
        console.log(this.$props)
        return this.$props.previewForm[this.key]
      },
      set(val) {
        let formData = { ...this.$props.previewForm }
        formData[this.key] = val
        this.$emit('setPreviewForm', formData)
      },
    },
  },
  methods: {
    saveConfig() {
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$emit('saveConfig', { key: this.key, config: this.form })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    cancel() {
      this.form = JSON.parse(JSON.stringify(this.$props.formData[this.key]))
    },
  },
  watch: {
    '$props.formData.members': {
      handler(val) {
        this.form = JSON.parse(JSON.stringify(val))
        this.loading = false
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
}
</script>

<style></style>
