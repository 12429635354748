<template>
  <div>
    <div class="list" v-show="myValue&&myValue.length !== 0">
      <draggable v-model="myValue" animation="500" draggable=".draggable">
        <div
          class="single-subject draggable"
          v-for="(category, index) in myValue"
          :key="category.id"
        >
          <div class="item">
            <!-- 操作栏 -->
            <div class="btn">
              <img src="../../../assets/images/operation.png" alt="" />
              <i @click="removeLine(index)" class="el-icon-delete"></i>
            </div>
            <div class="content">
              <div class="label">
                <span>分类名称：</span>
                <el-input
                  style="max-width: 300px"
                  v-model="myValue[index].name"
                  placeholder="请输入分类"
                ></el-input>
              </div>
            </div>
            <div style="padding: 8px">
              <Table
                v-model="myValue[index].children"
                :id="category.id"
                :limit="1"
              ></Table>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <!-- <p class="img-tips">提示：拖拽可以调整排序</p> -->
    <div class="add-btn" @click="addLine">+添加分类</div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Table from './Table.vue'
import { randomString } from '@/base/utils/tool'
export default {
  name: 'ProjectInstitution',
  components: { draggable, Table },
  props: {
    value: {
      type: Array,
    },
    limit:{
      type:Number,
      default:999,
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    value(val) {
      this.myValue = val
    },
  },
  computed: {
    myValue: {
      get() {
        return this.value?this.value:[]
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  created() {},
  methods: {
    // 移除
    removeLine(i) {
      this.$confirm('是否删除此分类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.myValue.splice(i, 1)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {})
    },
    // 添加
    addLine() {
      this.myValue.push({ children: [], name: '', id: randomString(8) })
      // this.$emit('input', this.myValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.img-tips {
  width: 194px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999;
  line-height: 12px;
  margin-top: 10px;
}
.list {
  // width: 500px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f5f7fa;
  .single-subject {
    display: flex;
    justify-content: space-between;
    .item {
      width: 100%;
      background-color: #fff;
      border-radius: 5px;
      margin: 5px 0;
      color: #909399;
      .btn {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(244, 244, 245, 1);
        .el-icon-delete {
          cursor: pointer;
        }
      }
      .content {
        margin: 10px;
        .label {
          display: flex;
          margin-bottom: 20px;
          color: #606266;
          span {
            width: 90px;
          }
          .tips {
            width: 194px;
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #999;
            line-height: 12px;
            margin-left: 10px;
          }
          .el-input {
            flex: 1;
          }
        }
      }
    }
    .flex {
      display: flex;
      padding-top: 10px;
      span {
        width: 15px;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }

  .del-btn {
    width: 30px;
    text-align: right;
    cursor: pointer;
  }
}

.add-btn {
  cursor:pointer;
  margin-top: 10px;
  width: 100%;
  width: 100%;
  height: 40px;
  border: 1px solid transparent;
  text-align: center;
  line-height: 40px;
  color: #ccc;
  background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(-45deg, #ccc 0, #ccc 0.25em, white 0, white 0.5em);
}

.el-icon-delete:hover {
  color: red;
}
</style>
